<template>
  <div class="giga-upload">
    <a-upload
      v-if="multiple"
      name="image"
      :file-list="[]"
      list-type="picture-card"
      class="avatar-uploader"
      @change="handleChange"
      @preview="handlePreview"
      :customRequest="() => ''"
      :multiple="multiple"
    >
      <div v-if="fileList.length < 100">
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else />
        <div style="margin-top: 8px">Upload</div>
      </div>
    </a-upload>
    <a-upload
      v-else
      :fileList="fileList"
      name="image"
      list-type="picture-card"
      class="avatar-uploader"
      :show-upload-list="false"
      @change="handleChange"
      :customRequest="() => ''"
    >
      <img v-if="imageUrl" :src="imageUrl" alt="" style="width:108px;height:108px" />
      <video v-else-if="videoUrl" :src="videoUrl" style="width:108px;height:108px;background:#000"></video>
      <div v-else>
        <loading-outlined v-if="loading"></loading-outlined>
        <plus-outlined v-else></plus-outlined>
        <div class="ant-upload-text">Upload</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <div v-if="imgs" class="ant-upload-list ant-upload-list-picture-card">
      <draggable 
        v-model="imgs" 
        group="people" 
        @start="drag=true" 
        @end="drag=false" 
        @change="change"
        item-key="id">
        <template #item="{element}">
          <div class="ant-upload-list-picture-card-container">
            <div class="ant-upload-list-item ant-upload-list-item-undefined ant-upload-list-item-list-type-picture-card">
              <div class="ant-upload-list-item-info">
                <span class="ant-upload-span">
                  <a class="ant-upload-list-item-thumbnail" :href="element.url" target="_blank" rel="noopener noreferrer">
                    <img :src="element.url" alt="22.png" class="ant-upload-list-item-image">
                  </a>
                  <a target="_blank" rel="noopener noreferrer" class="ant-upload-list-item-name" title="22.png" :href="element.url">22.png</a>
                </span>
              </div>
              <span class="ant-upload-list-item-actions">
                <button class="ant-btn ant-btn-text ant-btn-sm ant-upload-list-item-card-actions-btn ant-btn-icon-only" title="Remove file" type="button" @click="del(element.id)">
                  <span role="img" aria-label="delete" class="anticon anticon-delete">
                    <svg focusable="false" class="" data-icon="delete" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896">
                      <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z"></path>
                    </svg>
                  </span>
                </button>
              </span>
              <div class="ant-upload-list-item-progress" style="display: none;"></div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
// import { message } from 'ant-design-vue';
import { upLoadImage } from '../libs/upload'
import draggable from 'vuedraggable'

export default defineComponent({
  props: ['imageUrl', 'multiple', 'images', 'videoUrl', 'needGotoUrl'],
  components: {
    draggable
  },
  watch: {
    images(val) {
      this.imgs = val
    }
  },
  setup(props, {emit}) {
    const drag = ref(false)
    const imgs = ref(props.images)
    const fileList = ref([]);
    const loading = ref(false);
    const previewVisible = ref(false);
    const previewImage = ref('');
    const previewTitle = ref('');

    const handleChange = info => {
      if (info.file.status === 'uploading') {
        if (loading.value == true) return
        console.log(info)
        let fileList = info.fileList.filter(e => e.originFileObj)
        upLoad(fileList)
        loading.value = true;
        return;
      }
      if (info.file.status === 'removed') {
        emit('removeImg', info.file.id)
      }
    };

    const upLoad = (fileList) => {
      let file = fileList.shift()
      if (file) {
        upLoadImage(file, props.needGotoUrl).then(res => {
          console.log(res)
          let img = res.data.uploadFiles
          emit('setImg', img)
          upLoad(fileList)
        })
      } else {
        loading.value = false
      }
    }

    const del = (id) => {
      console.log(id)
      emit('removeImg', id)
    }

    const change = (e) => {
      let newIndex = e.moved.newIndex
      let oldIndex = e.moved.oldIndex
      let images = JSON.parse(JSON.stringify(props.images))
      let img = images.splice(oldIndex, 1)
      images.splice(newIndex, 0, img[0])
      emit('sortImg', images)
    }

    const handleCancel = () => {
      previewVisible.value = false;
      previewTitle.value = '';
    };

    const handlePreview = async file => {
      console.log(file)

      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
      previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    };

    return {
      imgs,
      drag,
      fileList,
      loading,
      handleChange,
      upLoad,
      handleCancel,
      handlePreview,
      change,
      del,
      previewImage,
      previewVisible,
      previewTitle
    };
  },
  methods: {
    
  },
});
</script>
<style lang="scss" scoped>
.giga-upload {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .img-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 104px;
      height: 104px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  
}
</style>
<style>
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>