import { uploadFiles } from '../http/api'
import { message } from 'ant-design-vue';

export const upLoadImage = (file, needGotoUrl) => {
  console.log(file)
  return new Promise((resolve, reject) => {
    let o = {
      query: `mutation($files: [FileInput!]!) {
        uploadFiles(files: $files) {
          id
          bucket
          mimeType
          name
          url
        }
      }`,
      variables: {
        files: {
          file,
          name: file.name,
          mimeType: file.type,
          gotoUrl: needGotoUrl ? file.name.split('.')[0] : null
        }
      }
    }
    let map = {
      'file': ['variables.files.file']
    }
    let fd = new FormData()
    fd.append('operations', JSON.stringify(o))
    fd.append('map', JSON.stringify(map))
    fd.append('file', file.originFileObj)
    uploadFiles(fd).then(imgFile => {
      resolve(imgFile)
      // return imgFile.data.uploadImage.fileUpload.fileUrl
    }).catch(err => {
      message.error('上传失败')
      reject(err)
    })
  })
}